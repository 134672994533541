import React from 'react'
// import { motion } from "framer-motion"


const Footer = () => {
  return (
    <div className='border-t border-zinc-950  sm:w-[400px] lg:w-[1080px] mt-8 lg:flex flex-col lg:flex-row lg:justify-between sm:justify-center px-6 lg:px-0'>
        <p className='text-gray-100 py-6 font-thin text-sm'> &copy; 2016 - 2024 | All rights reserved.</p>
        <p className='text-gray-100 py-6 font-thin text-xs'> Web Design & Development, Graphics Design, SEO Services in Slough, Berkshire (UK)</p>
            {/* <h1 className=' text-white my-2 sm:text-6xl  text-4xl font-bold  ml-6 sm:ml-5 uppercase pt-[60px] '> 
      <motion.div initial={{ opacity: 0,  y:50 }}
    animate={{ opacity: 1, scale: 1, y:0 }}
    transition={{ duration: 1, delay:1, }} >
        <span class="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
    MY CLIENTS 
  </span>
  </motion.div>

      </h1> */}
    </div>
  )
}

export default Footer
