import React from "react";
import Slider from "react-slick";
import PHOTO1 from '../assets/clients/smbgrowthboosters.jpg'
import PHOTO2 from '../assets/clients/autonomycars.jpg'
import PHOTO3 from '../assets/clients/barrytv.jpg'
import PHOTO4 from '../assets/clients/hillsidecare.jpg'
import PHOTO5 from '../assets/clients/metalogics.jpg'
import PHOTO6 from '../assets/clients/proper.jpg'
import PHOTO7 from '../assets/clients/qorelogix.jpg'
import PHOTO8 from '../assets/clients/xovohealth.jpg'
import PHOTO9 from '../assets/clients/avsocial.jpg'


const ClientSlider = () => {

    const settings = {
      dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };



  return (

      <div className=" container bg-black py-[40px] px-[50px]">
      <Slider {...settings}>
        <div>
          <h3 className="opacity-50 hover:opacity-100"><a href="https://smbgrowthbooster.com/" target="_blank" rel="noreferrer">
            <img src={PHOTO1} alt="smb growthbooster" width={200} /> 
            </a>
            </h3>
        </div>
        <div>
        <h3 className="opacity-50 hover:opacity-100"><a href="https://autonomyrentals.co.uk/" target="_blank" rel="noreferrer">
          <img src={PHOTO2} alt="autonomy cars" width={200} /> 
          </a>
          </h3>

        </div>
        <div>
        <h3 className="opacity-50 hover:opacity-100"><a href="https://barry.tv/" target="_blank" rel="noreferrer">
          <img src={PHOTO3} alt="barry.tv" width={200} /> 
          </a>
          </h3>

        </div>
        <div>
        <h3 className="opacity-50 hover:opacity-100"><a href="https://hillside-care.co.uk/" target="_blank" rel="noreferrer">
          <img src={PHOTO4} alt="hillside care uk" width={200}/> 
          </a>
          </h3>

        </div>
        <div>
        <h3 className="opacity-50 hover:opacity-100"><a href="https://metalogics.io/" target="_blank" rel="noreferrer">
          <img src={PHOTO5} alt="metalogics.io" width={200}/>
          </a>
           </h3>

        </div>
        <div>
        <h3 className="opacity-50 hover:opacity-100">          <a href="https://www.properconsulting.co.uk" target="_blank" rel="noreferrer" >
          <img src={PHOTO6} alt="proper consulting scotland" width={200} /> 
          </a>
          </h3>

        </div>
        <div>
        <h3 className="opacity-50 hover:opacity-100"><a href="https://qorelogix.com/" target="_blank" rel="noreferrer">
          <img src={PHOTO7} alt="qore logics" width={200}/> 
          </a>
          </h3>

        </div>
        <div>
        <h3 className="opacity-50 hover:opacity-100"><a href="https://www.xovohealth.co.uk/" target="_blank" rel="noreferrer">
          <img src={PHOTO8} alt="xovo health" width={200}/>
          </a>
           </h3>

        </div>
        <div>
        <h3 className="opacity-50 hover:opacity-100">
          <a href="https://avsocialwork.co.uk/" target="_blank" rel="noreferrer">
          <img src={PHOTO9} alt="av social work uk" width={200}/> 
          </a>
          </h3>

        </div>
      </Slider>
    </div>
  )
}

export default ClientSlider
