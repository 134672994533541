import React from 'react'
  import { motion } from "framer-motion"
import ClientSlider from './ClientSlider'


const Myclients = () => {
  return (
    <div className='flex flex-col'>
    <h1 className=' text-white my-2 lg:text-6xl  text-4xl font-bold  ml-0 lg:ml-5 uppercase pt-[60px] '> 
  <motion.div initial={{ opacity: 0,  y:50 }}
animate={{ opacity: 1, scale: 1, y:0 }}
transition={{ duration: 1, delay:1, }} >
    <span class="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
MY Clients 
</span>
</motion.div>

  </h1>
    <div className='bg-black w-[320px] lg:w-[1080px]'>
      <ClientSlider/>
    </div>
      </div>
  )
}

export default Myclients
