import React from 'react'
import { motion } from "framer-motion"
import tick from '../assets/icons/badge-check.svg'

const Services = () => {
  return (
    <div className= ' bg-black py-[30px]  flex flex-col justify-start '>
    <h1 className=' text-white my-2 lg:text-6xl  text-4xl font-bold  ml-2 lg:ml-5 uppercase pt-[60px] '> 
  <motion.div initial={{ opacity: 0,  y:50 }}
animate={{ opacity: 1, scale: 1, y:0 }}
transition={{ duration: 1, delay:1, }} >
    <span class="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
MY Services 
</span>
</motion.div>

  </h1>

  <div className='border-t mt-6 font-thin tracking-wider flex flex-col   border-zinc-900 py-8 px-0 lg:px-4  w-[350px] lg:w-[1080px] '>

<div className='flex flex-col items-start my-2 pb-3 lg:pb-0 lg:flex-row lg:items-center bg-slate-100 bg-opacity-10 border border-white border-opacity-20 px-2 rounded-md'>

  <h3 className='text-white tracking my-2 py-4 flex items-center text-md'>

    <img src={tick} width={28} alt='tick' className='mr-4'/> Web Design & Development 
    </h3>
    <p className='text-sky-500 ml-5 text-sm  pb-3 lg:pb-0'>
         (Wordpress / Elementor / Divi / WooCommerce)
        </p>
</div>
<div className='flex flex-col items-start my-2 pb-3 lg:pb-0 lg:flex-row lg:items-center bg-slate-100 bg-opacity-10 border border-white border-opacity-20 px-3 rounded-md'>

<h3 className='text-white tracking my-2 py-4 flex items-center text-md'>

<img src={tick} width={28} alt='tick' className='mr-4'/> Web Applications Development 
</h3>
<p className='text-sky-500 ml-5 text-sm pb-3 lg:pb-0'>
(Reactjs / Nextjs / Redux-toolkit)        </p>

</div>
<div className='flex flex-col items-start  my-2  pb-3 lg:pb-0 lg:flex-row lg:items-center bg-slate-100 bg-opacity-10 border border-white border-opacity-20 px-3 rounded-md'>

<h3 className='text-white tracking my-2 py-4 flex items-center text-md'>

<img src={tick} width={28} alt='tick' className='mr-4'/> Figma Design 
</h3>
<p className='text-sky-500 ml-5 text-sm  pb-3 lg:pb-0'>
(Website Design / Applications Design / Mobile Application Design / Adds Design)
        </p>
</div>
<div className='flex flex-col items-start  my-2 pb-3 lg:pb-0 lg:flex-row lg:items-center bg-slate-100 bg-opacity-10 border border-white border-opacity-20 px-3 rounded-md'>

<h3 className='text-white tracking my-2 py-4 flex items-center text-md'>

<img src={tick} width={28} alt='tick' className='mr-4'/> Graphics Designing
</h3>
<p className='text-sky-500 ml-5 text-sm  pb-3 lg:pb-0'>
(Brochure Design / Leaflets Design / Posters Design / Business Cards Design)
</p>
</div>
<div className='flex flex-col items-start  my-2 pb-3 lg:pb-0 lg:flex-row lg:items-center bg-slate-100 bg-opacity-10 border border-white border-opacity-20 px-3 rounded-md'>

<h3 className='text-white tracking my-2 py-4 flex items-center text-md'>

<img src={tick} width={28} alt='tick' className='mr-4'/> Search Engine Optimisaztion (SEO)
</h3>
<p className='text-sky-500 ml-5 text-sm pb-3 lg:pb-0'>
(Local SEO / On-Page SEO / Off-Page SEO / Guest Posting)</p>
</div>


  </div>
      
    </div>
  )
}

export default Services
