
import Hero from "../components/Hero"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Myclients from "../components/Myclients";
import Footer from "../components/Footer";
import Services from "../components/Services";



function Home(){

return(
< div className="bg-black flex flex-col items-center justify-start">
<Hero/>
{/* <ClientSlider/> */}
<Myclients/>
<Services/>
<Footer/>
</div>
)

}


export default Home